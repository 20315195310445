
<input v-if="field.data_type_enum === 10"
    type="text" class="w-100 from-inputs" 
    v-model="localValue" 
    :placeholder="field.placeholder" 
    :name="field.slug" 
    :data-vv-as="field.title"
    :disabled="field.disabled"
    v-validate="validations">

<textarea v-else-if="field.data_type_enum === 11"
    class="w-100 from-inputs" 
    v-model="localValue" 
    :placeholder="field.placeholder" 
    :name="field.slug" 
    :data-vv-as="field.title"
    v-validate="validations"
    cols="30" 
    rows="3"/>
