<template>
    <input v-if="field.data_type_enum === 10"
        type="text" class="w-100 from-inputs" 
        v-model="localValue" 
        :placeholder="field.placeholder" 
        :name="field.slug" 
        :data-vv-as="field.title"
        :disabled="field.disabled"
        v-validate="validations">

    <textarea v-else-if="field.data_type_enum === 11"
        class="w-100 from-inputs" 
        v-model="localValue" 
        :placeholder="field.placeholder" 
        :name="field.slug" 
        :data-vv-as="field.title"
        v-validate="validations"
        cols="30" 
        rows="3"/>
</template>
<script>
export default {
    inject: ['$validator'],
    props: {
        field: {
            type: Object,
            required: true
        },
        value: {
            required: true
        },
        validations: {
            type: String,
            default: 'required'
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
}
</script>
<style scoped>
textarea {
    min-height: 78px;
    max-height: 312px;
}
</style>